"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable_1 = require("../utils/Observable");
var CaretPos = /** @class */ (function () {
    function CaretPos(parent) {
        this.selStart = 0;
        this.selEnd = 0;
        this.x = 0;
        this.y = 0;
        this.handlers = {};
        this.parent = parent;
        this.doc = parent.doc;
    }
    CaretPos.prototype.moveUp = function () {
        var start = this.doc.selStart;
        if (start > 0) {
            this.selStart = start - 1;
            this.selEnd = start - 1;
        }
        else {
            this.selStart = 0;
            this.selEnd = 0;
        }
        this.emit('moved');
    };
    CaretPos.prototype.moveDown = function () {
        var end = this.doc.selEnd;
        if (end < this.doc.src.length - 1) {
            this.selStart = end + 1;
            this.selEnd = end + 1;
        }
        else {
            this.selStart = end;
            this.selEnd = end;
        }
        this.emit('moved');
    };
    CaretPos.prototype.moveLeft = function () {
        var nearest = this.parent.sourcePanel.findVisuallyNearestCharInNextLine(this.x, this.y);
    };
    CaretPos.prototype.setPosition = function (x, y) {
        this.x = x;
        this.y = y;
    };
    CaretPos.prototype.moveRight = function () {
        console.log(this.parent.sourcePanel.findVisuallyNearestCharInPrevLine(this.x, this.y));
    };
    return CaretPos;
}());
exports.default = CaretPos;
Observable_1.applyMixins(CaretPos, [Observable_1.Observable]);
