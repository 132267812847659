"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable_1 = require("../utils/Observable");
var HiddenTextArea = /** @class */ (function () {
    function HiddenTextArea(parent, $el) {
        this.handlers = {};
        this.parent = parent;
        this.doc = parent.doc;
        this.$el = $el;
        this.observeFocus();
        this.observeSrc();
        this.observeEl();
        this.observeSelection();
        this.observeEditor();
        this.observeKeyEvents();
    }
    HiddenTextArea.prototype.setValue = function (value) {
        this.$el.value = value;
    };
    HiddenTextArea.prototype.focus = function () {
        this.$el.focus();
    };
    HiddenTextArea.prototype.hasFocus = function () {
        return this.$el.matches(':focus');
    };
    HiddenTextArea.prototype.observeSrc = function () {
        var _this = this;
        var events = ['input', 'click', 'oncut', 'onpaste', 'keyup'];
        events.forEach(function (e) {
            _this.$el.addEventListener(e, function () {
                _this.doc.updateSource(_this.$el.value);
                _this.setScroll();
            });
        });
    };
    HiddenTextArea.prototype.observeFocus = function () {
        var _this = this;
        this.$el.addEventListener('focus', function (e) {
            // console.log('focus');
            _this.emit('focus');
        });
        this.$el.addEventListener('blur', function (e) {
            // console.log('blur');
            _this.emit('blur');
        });
    };
    HiddenTextArea.prototype.observeSelection = function () {
        var _this = this;
        setInterval(function () {
            if (_this.$el.selectionStart !== _this.doc.selStart ||
                _this.$el.selectionEnd !== _this.doc.selEnd) {
                _this.doc.setSelection(_this.$el.selectionStart, _this.$el.selectionEnd);
                var char = _this.parent.sourcePanel.currentCaretEl();
                var rect = char.getBoundingClientRect();
                _this.parent.caret.x = rect.left;
                _this.parent.caret.y = rect.top;
            }
        }, 10);
    };
    HiddenTextArea.prototype.observeEl = function () {
        var _this = this;
        this.$el.addEventListener('scroll', function (e) {
            _this.setScroll();
        });
        this.$el.addEventListener('resize', function (e) {
            _this.setScroll();
        });
        this.$el.addEventListener('focus', function (e) {
            _this.parent.editorState.setFocus(true);
        });
        this.$el.addEventListener('blur', function (e) {
            _this.parent.editorState.setFocus(false);
        });
    };
    HiddenTextArea.prototype.observeEditor = function () {
        var _this = this;
        this.parent.editorState.on('resize', function () {
            var edt = _this.parent.editorState;
            _this.$el.style.width = edt.height - 30 + 'px';
            _this.$el.style.height = edt.widthPx();
            _this.$el.style.left = edt.widthPx();
        });
    };
    HiddenTextArea.prototype.observeKeyEvents = function () {
        var _this = this;
        this.$el.addEventListener('compositionstart', function (e) { });
        this.$el.addEventListener('compositionupdate', function (e) {
            var data = e.data;
            var start = _this.doc.selStart - data.length;
            var end = _this.doc.selStart;
            _this.doc.removeClass('overline', 0, _this.doc.chars.length - 1);
            _this.doc.addClass('overline', start, end);
            console.log(start, end);
            _this.doc.emit('updated');
        });
        this.$el.addEventListener('compositionend', function (e) {
            console.log('end');
            _this.doc.removeClass('overline', 0, _this.doc.chars.length - 1);
            _this.doc.emit('updated');
        });
        this.$el.addEventListener('keydown', function (e) {
            if (e.key === 'ArrowUp') {
                _this.moveUp();
                e.preventDefault();
            }
            else if (e.key === 'ArrowRight') {
                _this.moveRight();
                e.preventDefault();
            }
            else if (e.key === 'ArrowDown') {
                _this.moveDown();
                e.preventDefault();
            }
            else if (e.key === 'ArrowLeft') {
                _this.moveLeft();
                e.preventDefault();
            }
        });
    };
    HiddenTextArea.prototype.moveUp = function () {
        var start = this.$el.selectionStart;
        if (start > 0) {
            this.$el.selectionStart = start - 1;
            this.$el.selectionEnd = start - 1;
        }
        else {
            this.$el.selectionStart = start;
            this.$el.selectionEnd = start;
        }
    };
    HiddenTextArea.prototype.moveDown = function () {
        var end = this.$el.selectionEnd;
        if (end < this.$el.value.length) {
            this.$el.selectionStart = end + 1;
            this.$el.selectionEnd = end + 1;
        }
        else {
            this.$el.selectionStart = end;
            this.$el.selectionEnd = end;
        }
    };
    HiddenTextArea.prototype.moveLeft = function () {
        var x = this.parent.caret.x;
        var y = this.parent.caret.y;
        var chars = this.parent.sourcePanel.charElArray();
        var nearest = this.parent.sourcePanel.findVisuallyNearestCharInNextLine(x, y);
        console.log(nearest);
        var pos = this.$el.value.length;
        if (nearest) {
            pos = chars.indexOf(nearest);
        }
        this.$el.selectionStart = pos;
        this.$el.selectionEnd = pos;
    };
    HiddenTextArea.prototype.moveRight = function () {
        var x = this.parent.caret.x;
        var y = this.parent.caret.y;
        var chars = this.parent.sourcePanel.charElArray();
        var nearest = this.parent.sourcePanel.findVisuallyNearestCharInPrevLine(x, y);
        var pos = 0;
        if (nearest) {
            pos = chars.indexOf(nearest);
        }
        this.$el.selectionStart = pos;
        this.$el.selectionEnd = pos;
    };
    HiddenTextArea.prototype.setScroll = function () {
        // console.log(this.$el.scrollTop, this.$el.scrollHeight);
        this.parent.editorState.setCurrentScroll(this.$el.scrollTop);
        this.parent.editorState.setMaxScroll(this.$el.scrollHeight);
    };
    HiddenTextArea.prototype.setSelection = function (start, end) {
        this.$el.selectionStart = start;
        this.$el.selectionEnd = end;
    };
    return HiddenTextArea;
}());
exports.default = HiddenTextArea;
// mixin
Observable_1.applyMixins(HiddenTextArea, [Observable_1.Observable]);
