"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable = /** @class */ (function () {
    function Observable() {
    }
    Observable.prototype.emit = function (event) {
        var _this = this;
        if (!this.handlers[event])
            return;
        this.handlers[event].forEach(function (handler) { return handler(_this); });
    };
    Observable.prototype.on = function (events, handler) {
        var _this = this;
        events.split(' ').forEach(function (event) {
            if (!_this.handlers[event])
                _this.handlers[event] = [];
            _this.handlers[event].push(handler);
        });
    };
    return Observable;
}());
exports.Observable = Observable;
function applyMixins(derivedCtor, baseCtors) {
    baseCtors.forEach(function (baseCtor) {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(function (name) {
            derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
    });
}
exports.applyMixins = applyMixins;
