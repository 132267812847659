"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActionMenu = /** @class */ (function () {
    function ActionMenu(parent, $el) {
        this.parent = parent;
        this.$el = $el;
        this.actions = parent.mode.actions;
        this.observeSelection();
        this.observeScroll();
    }
    ActionMenu.prototype.observeSelection = function () {
        var _this = this;
        var doc = this.parent.doc;
        doc.on('selection-changed', function () { return _this.update(); });
    };
    ActionMenu.prototype.observeScroll = function () {
        var _this = this;
        var editorState = this.parent.editorState;
        editorState.on('scroll', function () { return _this.hide(); });
    };
    ActionMenu.prototype.showAt = function (rect) {
        var parentWidth = this.$el.parentElement.clientWidth;
        if (rect.left < 0.7 * parentWidth) {
            this.$el.style.left = rect.right + 'px';
            this.$el.style.right = null;
            this.$el.style.top = rect.top + 'px';
        }
        else {
            this.$el.style.left = null;
            this.$el.style.right = window.innerWidth - rect.left + 'px';
            this.$el.style.top = rect.top + 'px';
        }
        this.$el.style.display = 'block';
    };
    ActionMenu.prototype.hide = function () {
        this.$el.style.display = 'none';
        if (this.timer)
            clearTimeout(this.timer);
    };
    ActionMenu.prototype.waitAndShowActionMenu = function () {
        var _this = this;
        if (this.timer)
            clearTimeout(this.timer);
        this.timer = window.setTimeout(function () {
            _this.$el.innerHTML = '';
            var actions = _this.actions(_this.parent);
            if (!actions)
                return null;
            _this.renderActionGroup(actions).forEach(function (e) { return _this.$el.appendChild(e); });
            var charEl = _this.parent.sourcePanel.getCharElAt(_this.parent.doc.selStart);
            var rect = charEl.getBoundingClientRect();
            _this.showAt(rect);
        }, 1000);
    };
    ActionMenu.prototype.isActionGroup = function (ag) {
        return ag.name !== undefined && ag.children !== undefined;
    };
    ActionMenu.prototype.isAction = function (a) {
        return a.name !== undefined && a.action !== undefined;
    };
    ActionMenu.prototype.showSubmenu = function (parentEl, children) {
        var _this = this;
        // create dom
        var submenu = document.createElement('div');
        submenu.classList.add('action-menu');
        var items = children.forEach(function (ch) {
            var el = _this.renderAction(ch);
            submenu.appendChild(el);
        });
        // set style
        submenu.style.position = 'fixed';
        var rect = parentEl.getBoundingClientRect();
        if (rect.right < 0.7 * window.outerWidth) {
            submenu.style.left = rect.width + 'px';
        }
        else {
            submenu.style.right = -rect.width + 'px';
        }
        parentEl.appendChild(submenu);
    };
    ActionMenu.prototype.renderAction = function (a) {
        var _this = this;
        if (this.isActionGroup(a)) {
            var group_1 = document.createElement('div');
            group_1.classList.add('action-item');
            group_1.classList.add('action-group');
            group_1.innerHTML = a.name + "<span class=\"right-arrow\">\u25B6</span>";
            group_1.addEventListener('mouseenter', function () {
                _this.showSubmenu(group_1, a.children);
            });
            group_1.addEventListener('mouseleave', function () {
                group_1.getElementsByClassName('action-menu')[0].remove();
            });
            return group_1;
        }
        else {
            var action = document.createElement('div');
            action.classList.add('action-item');
            action.innerText = a.name;
            action.addEventListener('click', function () {
                a.action(_this.parent);
            });
            return action;
        }
    };
    ActionMenu.prototype.renderActionGroup = function (ag) {
        var _this = this;
        var elms = [];
        var header = document.createElement('div');
        header.classList.add('action-header');
        elms.push(header);
        header.innerText = ag.name;
        ag.children.forEach(function (group) {
            var item = _this.renderAction(group);
            elms.push(item);
        });
        return elms;
    };
    ActionMenu.prototype.update = function () {
        this.hide();
        this.waitAndShowActionMenu();
    };
    return ActionMenu;
}());
exports.default = ActionMenu;
