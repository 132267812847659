"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tokenizer_1 = require("./tokenizer");
var parser_1 = require("./parser");
var actions_1 = require("./actions");
var converter_1 = require("./converter");
var mode = {
    name: 'koji',
    parse: parser_1.parse,
    tokenizer: tokenizer_1.default,
    tokenClassMap: {
        INLINE_START: 'inline',
        INLINE_NAME: 'inline-name',
        INLINE_SEP: 'inline',
        COLON: 'inline',
        INLINE_END: 'inline',
        FURIGANA_START: 'furigana',
        FURIGANA_END: 'furigana',
        FURIGANA_SEP: 'furigana',
        KAERI_START: 'kaeri',
        KAERI_END: 'kaeri',
        FURIGANA: 'furigana',
        KAERITEN: 'kaeri',
        OKURIGANA: 'okuri',
        OKURIGANA_START: 'okuri',
        OKURIGANA_END: 'okuri',
        ANNOTATION: 'annotation',
        BLOCK_START: 'block',
        BLOCK_NAME: 'block-name',
        BLOCK_CLOSING_START: 'block',
        BLOCK_END: 'block',
        ANNO_START: 'annotation',
        ANNO_END: 'annotation',
        TEXT_SEGMENT: null,
        UNKNOWN: null
    },
    actions: actions_1.default,
    converters: {
        html: converter_1.convertToHTML
    }
};
exports.default = mode;
