"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable_1 = require("../utils/Observable");
var EditorState = /** @class */ (function () {
    function EditorState() {
        this.currentScroll = 0;
        this.handlers = {};
    }
    EditorState.prototype.setSize = function (width, height) {
        if (this.width !== width || this.height !== height) {
            this.width = width;
            this.height = height;
            this.emit('resize');
        }
    };
    EditorState.prototype.widthPx = function () {
        return this.width + 'px';
    };
    EditorState.prototype.heightPx = function () {
        return this.height + 'px';
    };
    EditorState.prototype.setFocus = function (focus) {
        if (focus !== this.focus) {
            this.focus = focus;
            if (focus) {
                this.emit('focus');
            }
            else {
                this.emit('blur');
            }
        }
    };
    EditorState.prototype.setCurrentScroll = function (num) {
        if (num !== this.currentScroll) {
            this.currentScroll = num;
            this.emit('scroll');
        }
    };
    EditorState.prototype.setMaxScroll = function (num) {
        if (num !== this.maxScroll) {
            this.maxScroll = num;
            this.emit('scroll');
        }
    };
    return EditorState;
}());
exports.default = EditorState;
Observable_1.applyMixins(EditorState, [Observable_1.Observable]);
