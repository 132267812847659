"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Display = /** @class */ (function () {
    function Display(parent, $el) {
        this.parent = parent;
        this.$el = $el;
        this.observeEditor();
    }
    Display.prototype.observeEditor = function () {
        var _this = this;
        this.parent.editorState.on('resize', function () {
            var edt = _this.parent.editorState;
            _this.$el.style.width = edt.widthPx();
            _this.$el.style.height = edt.heightPx();
        });
        this.parent.editorState.on('scroll', function () {
            _this.syncScroll();
        });
    };
    Display.prototype.syncScroll = function () {
        var offset = this.$el.scrollWidth -
            this.$el.clientWidth -
            this.parent.editorState.currentScroll;
        this.$el.scrollTo(offset, 0);
    };
    return Display;
}());
exports.default = Display;
