module.exports = /*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */
(function() {
  "use strict";

  function peg$subclass(child, parent) {
    function ctor() { this.constructor = child; }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
  }

  function peg$SyntaxError(message, expected, found, location) {
    this.message  = message;
    this.expected = expected;
    this.found    = found;
    this.location = location;
    this.name     = "SyntaxError";

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, peg$SyntaxError);
    }
  }

  peg$subclass(peg$SyntaxError, Error);

  peg$SyntaxError.buildMessage = function(expected, found) {
    var DESCRIBE_EXPECTATION_FNS = {
          literal: function(expectation) {
            return "\"" + literalEscape(expectation.text) + "\"";
          },

          "class": function(expectation) {
            var escapedParts = "",
                i;

            for (i = 0; i < expectation.parts.length; i++) {
              escapedParts += expectation.parts[i] instanceof Array
                ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
                : classEscape(expectation.parts[i]);
            }

            return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
          },

          any: function(expectation) {
            return "any character";
          },

          end: function(expectation) {
            return "end of input";
          },

          other: function(expectation) {
            return expectation.description;
          }
        };

    function hex(ch) {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s) {
      return s
        .replace(/\\/g, '\\\\')
        .replace(/"/g,  '\\"')
        .replace(/\0/g, '\\0')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
    }

    function classEscape(s) {
      return s
        .replace(/\\/g, '\\\\')
        .replace(/\]/g, '\\]')
        .replace(/\^/g, '\\^')
        .replace(/-/g,  '\\-')
        .replace(/\0/g, '\\0')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
    }

    function describeExpectation(expectation) {
      return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
    }

    function describeExpected(expected) {
      var descriptions = new Array(expected.length),
          i, j;

      for (i = 0; i < expected.length; i++) {
        descriptions[i] = describeExpectation(expected[i]);
      }

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found) {
      return found ? "\"" + literalEscape(found) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  };

  function peg$parse(input, options) {
    options = options !== void 0 ? options : {};

    var peg$FAILED = {},

        peg$startRuleFunctions = { Document: peg$parseDocument },
        peg$startRuleFunction  = peg$parseDocument,

        peg$c0 = function(content) {
            	return {
                	type: 'root', 
                    typeJP: 'ルート',
                	content: concatTextSegments(content)
                    }
            },
        peg$c1 = function(sugar) {
            	sugar.location = location()
            	return sugar
            },
        peg$c2 = "\uFF5C",
        peg$c3 = peg$literalExpectation("\uFF5C", false),
        peg$c4 = "\uFF08",
        peg$c5 = peg$literalExpectation("\uFF08", false),
        peg$c6 = "\uFF09",
        peg$c7 = peg$literalExpectation("\uFF09", false),
        peg$c8 = function(body, right) {
            return {
         	   type: "furigana",
               typeJP: "振り仮名",
          	 	content: [{type: 'text', value: body.join("")}], 
        	    kana: concatTextSegments(right)}
            },
        peg$c9 = peg$otherExpectation("\u96E3\u8AAD"),
        peg$c10 = "\u25A0",
        peg$c11 = peg$literalExpectation("\u25A0", false),
        peg$c12 = function(illegible) {
            	return{
                	type: "illegible",
                    typeJP: "難読",
                    size: illegible.length
                }
            },
        peg$c13 = peg$otherExpectation("\u866B\u640D"),
        peg$c14 = "\u25A1",
        peg$c15 = peg$literalExpectation("\u25A1", false),
        peg$c16 = function(illegible) {
            	return{
                	type: "illegible",
                    typeJP: "虫損",
                    size: illegible.length
                }
            },
        peg$c17 = peg$otherExpectation("\u6CE8\u91C8"),
        peg$c18 = "\u3010",
        peg$c19 = peg$literalExpectation("\u3010", false),
        peg$c20 = "\u3011",
        peg$c21 = peg$literalExpectation("\u3011", false),
        peg$c22 = function(comment) {
            	return {
                	type: "comment",
                    typeJP: "注釈",
                    content:  concatTextSegments(comment)
                }
            },
        peg$c23 = peg$otherExpectation("\u8FD4\u308A\u70B9"),
        peg$c24 = "\uFF5B",
        peg$c25 = peg$literalExpectation("\uFF5B", false),
        peg$c26 = /^[\u30EC\u4E00\u4E8C\u4E09\u4E0A\u4E2D\u4E0B\u7532\u4E59\u4E19\u70B9]/,
        peg$c27 = peg$classExpectation(["\u30EC", "\u4E00", "\u4E8C", "\u4E09", "\u4E0A", "\u4E2D", "\u4E0B", "\u7532", "\u4E59", "\u4E19", "\u70B9"], false, false),
        peg$c28 = "\uFF5D",
        peg$c29 = peg$literalExpectation("\uFF5D", false),
        peg$c30 = function(kaeri) {
            	return {
                	type: "kaeriten",
                    typeJP: "返り点",
                    mark: kaeri.join("")            
                }
            },
        peg$c31 = peg$otherExpectation("\u9001\u308A\u4EEE\u540D"),
        peg$c32 = "\u3014",
        peg$c33 = peg$literalExpectation("\u3014", false),
        peg$c34 = "\u3015",
        peg$c35 = peg$literalExpectation("\u3015", false),
        peg$c36 = function(okuri) {
            	return {
                	type: "okurigana",
                    typeJP: "送り仮名",
                    content: concatTextSegments(okuri)
                }
            },
        peg$c37 = peg$otherExpectation("\u30D6\u30ED\u30C3\u30AF\u8981\u7D20"),
        peg$c38 = "\uFF3B",
        peg$c39 = peg$literalExpectation("\uFF3B", false),
        peg$c40 = "\uFF3D\n",
        peg$c41 = peg$literalExpectation("\uFF3D\n", false),
        peg$c42 = "\uFF3B\uFF0F",
        peg$c43 = peg$literalExpectation("\uFF3B\uFF0F", false),
        peg$c44 = "\uFF3D",
        peg$c45 = peg$literalExpectation("\uFF3D", false),
        peg$c46 = function(name1, attrs, content, name2) {return name1 === name2},
        peg$c47 = function(name1, attrs, content, name2) {
                	return blockAST(name1, attrs, content)
                 },
        peg$c48 = peg$otherExpectation("\u30D6\u30ED\u30C3\u30AF\u8981\u7D20\u540D"),
        peg$c49 = "\u8868\u7D19",
        peg$c50 = peg$literalExpectation("\u8868\u7D19", false),
        peg$c51 = "\u88CF\u8868\u7D19",
        peg$c52 = peg$literalExpectation("\u88CF\u8868\u7D19", false),
        peg$c53 = "\u5E8F\u6587",
        peg$c54 = peg$literalExpectation("\u5E8F\u6587", false),
        peg$c55 = "\u8DCB\u6587",
        peg$c56 = peg$literalExpectation("\u8DCB\u6587", false),
        peg$c57 = "\u7AE0\u6BB5",
        peg$c58 = peg$literalExpectation("\u7AE0\u6BB5", false),
        peg$c59 = "\u5965\u66F8",
        peg$c60 = peg$literalExpectation("\u5965\u66F8", false),
        peg$c61 = "\u8B58\u8A9E",
        peg$c62 = peg$literalExpectation("\u8B58\u8A9E", false),
        peg$c63 = "\u520A\u8A18",
        peg$c64 = peg$literalExpectation("\u520A\u8A18", false),
        peg$c65 = "\u5965\u4ED8",
        peg$c66 = peg$literalExpectation("\u5965\u4ED8", false),
        peg$c67 = "\u88CF\u66F8\u304D",
        peg$c68 = peg$literalExpectation("\u88CF\u66F8\u304D", false),
        peg$c69 = "\u8CBC\u7D19",
        peg$c70 = peg$literalExpectation("\u8CBC\u7D19", false),
        peg$c71 = "\u7D19\u80CC",
        peg$c72 = peg$literalExpectation("\u7D19\u80CC", false),
        peg$c73 = "\u5C01\u9762",
        peg$c74 = peg$literalExpectation("\u5C01\u9762", false),
        peg$c75 = "\u8535\u66F8\u7968",
        peg$c76 = peg$literalExpectation("\u8535\u66F8\u7968", false),
        peg$c77 = "\u548C\u6B4C",
        peg$c78 = peg$literalExpectation("\u548C\u6B4C", false),
        peg$c79 = "\u4FF3\u53E5",
        peg$c80 = peg$literalExpectation("\u4FF3\u53E5", false),
        peg$c81 = "\u6F22\u6587",
        peg$c82 = peg$literalExpectation("\u6F22\u6587", false),
        peg$c83 = "\u6F22\u8A69",
        peg$c84 = peg$literalExpectation("\u6F22\u8A69", false),
        peg$c85 = "\u5B57\u4E0B\u3052\u4E00",
        peg$c86 = peg$literalExpectation("\u5B57\u4E0B\u3052\u4E00", false),
        peg$c87 = "\u5B57\u4E0B\u3052\u4E8C",
        peg$c88 = peg$literalExpectation("\u5B57\u4E0B\u3052\u4E8C", false),
        peg$c89 = "\u5B57\u4E0B\u3052\u4E09",
        peg$c90 = peg$literalExpectation("\u5B57\u4E0B\u3052\u4E09", false),
        peg$c91 = "\u5B57\u4E0B\u3052\u56DB",
        peg$c92 = peg$literalExpectation("\u5B57\u4E0B\u3052\u56DB", false),
        peg$c93 = "\u5B57\u4E0B\u3052\u4E94",
        peg$c94 = peg$literalExpectation("\u5B57\u4E0B\u3052\u4E94", false),
        peg$c95 = "\u8868",
        peg$c96 = peg$literalExpectation("\u8868", false),
        peg$c97 = "\u7CFB\u56F3",
        peg$c98 = peg$literalExpectation("\u7CFB\u56F3", false),
        peg$c99 = "\u5730\u56F3",
        peg$c100 = peg$literalExpectation("\u5730\u56F3", false),
        peg$c101 = "\u7D75",
        peg$c102 = peg$literalExpectation("\u7D75", false),
        peg$c103 = peg$otherExpectation("\u30A4\u30F3\u30E9\u30A4\u30F3\u8981\u7D20"),
        peg$c104 = "\u300A",
        peg$c105 = peg$literalExpectation("\u300A", false),
        peg$c106 = "\uFF1A",
        peg$c107 = peg$literalExpectation("\uFF1A", false),
        peg$c108 = "\u300B",
        peg$c109 = peg$literalExpectation("\u300B", false),
        peg$c110 = function(name, attrs, content, extra) {
            	extra = extra.map(e => e[1])
            	return inlineAST(name, attrs, content, extra)
            },
        peg$c111 = "\u53F2\u6599",
        peg$c112 = peg$literalExpectation("\u53F2\u6599", false),
        peg$c113 = "\u4EBA\u7269",
        peg$c114 = peg$literalExpectation("\u4EBA\u7269", false),
        peg$c115 = "\u5834\u6240",
        peg$c116 = peg$literalExpectation("\u5834\u6240", false),
        peg$c117 = "\u5730\u7406",
        peg$c118 = peg$literalExpectation("\u5730\u7406", false),
        peg$c119 = "\u5EFA\u7269",
        peg$c120 = peg$literalExpectation("\u5EFA\u7269", false),
        peg$c121 = "\u65E5\u6642",
        peg$c122 = peg$literalExpectation("\u65E5\u6642", false),
        peg$c123 = "\u4E8B\u9805",
        peg$c124 = peg$literalExpectation("\u4E8B\u9805", false),
        peg$c125 = "\u6570\u91CF",
        peg$c126 = peg$literalExpectation("\u6570\u91CF", false),
        peg$c127 = "\u5DEE\u51FA\u4EBA",
        peg$c128 = peg$literalExpectation("\u5DEE\u51FA\u4EBA", false),
        peg$c129 = "\u53D7\u53D6\u4EBA",
        peg$c130 = peg$literalExpectation("\u53D7\u53D6\u4EBA", false),
        peg$c131 = "\u984C",
        peg$c132 = peg$literalExpectation("\u984C", false),
        peg$c133 = "\u5916\u984C",
        peg$c134 = peg$literalExpectation("\u5916\u984C", false),
        peg$c135 = "\u5185\u984C",
        peg$c136 = peg$literalExpectation("\u5185\u984C", false),
        peg$c137 = "\u624B\u6CA2\u8005",
        peg$c138 = peg$literalExpectation("\u624B\u6CA2\u8005", false),
        peg$c139 = "\u5E8F\u5E74",
        peg$c140 = peg$literalExpectation("\u5E8F\u5E74", false),
        peg$c141 = "\u8DCB\u5E74",
        peg$c142 = peg$literalExpectation("\u8DCB\u5E74", false),
        peg$c143 = "\u5272\u66F8",
        peg$c144 = peg$literalExpectation("\u5272\u66F8", false),
        peg$c145 = "\u56F2\u3044\u66F8\u304D",
        peg$c146 = peg$literalExpectation("\u56F2\u3044\u66F8\u304D", false),
        peg$c147 = "\u8FCE\u3048\u4EEE\u540D",
        peg$c148 = peg$literalExpectation("\u8FCE\u3048\u4EEE\u540D", false),
        peg$c149 = "\u9001\u308A\u4EEE\u540D",
        peg$c150 = peg$literalExpectation("\u9001\u308A\u4EEE\u540D", false),
        peg$c151 = "\u95D5\u5B57",
        peg$c152 = peg$literalExpectation("\u95D5\u5B57", false),
        peg$c153 = "\u5C0F\u66F8\u304D",
        peg$c154 = peg$literalExpectation("\u5C0F\u66F8\u304D", false),
        peg$c155 = "\u898B\u305B\u6D88\u3061",
        peg$c156 = peg$literalExpectation("\u898B\u305B\u6D88\u3061", false),
        peg$c157 = "\u8A02\u6B63",
        peg$c158 = peg$literalExpectation("\u8A02\u6B63", false),
        peg$c159 = "\u508D\u6CE8",
        peg$c160 = peg$literalExpectation("\u508D\u6CE8", false),
        peg$c161 = "\u52D8\u8FD4",
        peg$c162 = peg$literalExpectation("\u52D8\u8FD4", false),
        peg$c163 = "\u97F3\u5408\u7B26",
        peg$c164 = peg$literalExpectation("\u97F3\u5408\u7B26", false),
        peg$c165 = "\u8A13\u5408\u7B26",
        peg$c166 = peg$literalExpectation("\u8A13\u5408\u7B26", false),
        peg$c167 = "\u5408\u70B9",
        peg$c168 = peg$literalExpectation("\u5408\u70B9", false),
        peg$c169 = "\u58A8\u683C",
        peg$c170 = peg$literalExpectation("\u58A8\u683C", false),
        peg$c171 = "\u508D\u70B9",
        peg$c172 = peg$literalExpectation("\u508D\u70B9", false),
        peg$c173 = "\u6731\u5F15\u5DE6\u5358",
        peg$c174 = peg$literalExpectation("\u6731\u5F15\u5DE6\u5358", false),
        peg$c175 = "\u6731\u5F15\u53F3\u5358",
        peg$c176 = peg$literalExpectation("\u6731\u5F15\u53F3\u5358", false),
        peg$c177 = "\u6731\u5F15\u4E2D\u5358",
        peg$c178 = peg$literalExpectation("\u6731\u5F15\u4E2D\u5358", false),
        peg$c179 = "\u6731\u5F15\u5DE6\u8907",
        peg$c180 = peg$literalExpectation("\u6731\u5F15\u5DE6\u8907", false),
        peg$c181 = "\u6731\u5F15\u53F3\u8907",
        peg$c182 = peg$literalExpectation("\u6731\u5F15\u53F3\u8907", false),
        peg$c183 = "\u6731\u5F15\u4E2D\u8907",
        peg$c184 = peg$literalExpectation("\u6731\u5F15\u4E2D\u8907", false),
        peg$c185 = "\u6731\u5F15\u7BB1",
        peg$c186 = peg$literalExpectation("\u6731\u5F15\u7BB1", false),
        peg$c187 = "\u82B1\u62BC",
        peg$c188 = peg$literalExpectation("\u82B1\u62BC", false),
        peg$c189 = "\u5370",
        peg$c190 = peg$literalExpectation("\u5370", false),
        peg$c191 = "\u8535\u66F8\u5370",
        peg$c192 = peg$literalExpectation("\u8535\u66F8\u5370", false),
        peg$c193 = peg$otherExpectation("\u30A4\u30F3\u30E9\u30A4\u30F3\u5185\u5BB9"),
        peg$c194 = function(content) {
            	return concatTextSegments(content)
            },
        peg$c195 = peg$otherExpectation("\u30D6\u30ED\u30C3\u30AF\u5185\u5BB9"),
        peg$c196 = peg$otherExpectation("\u6587\u5B57\u5217"),
        peg$c197 = function(text) {return {type: "segment", value: text.join(""), location: location()}},
        peg$c198 = peg$otherExpectation("\u5C5E\u6027"),
        peg$c199 = function(id, classList) {
            	let attrs = {}
                if (id) attrs.id = id
                if (classList.length > 0) attrs.classList = classList
            	return attrs
            },
        peg$c200 = peg$otherExpectation("ID"),
        peg$c201 = /^[\uFF03#]/,
        peg$c202 = peg$classExpectation(["\uFF03", "#"], false, false),
        peg$c203 = function(id) {
            	return id.join("")
            },
        peg$c204 = peg$otherExpectation("\u30AF\u30E9\u30B9"),
        peg$c205 = /^[\uFF0A*]/,
        peg$c206 = peg$classExpectation(["\uFF0A", "*"], false, false),
        peg$c207 = function(cls) {
            	return cls.join("")
            },
        peg$c208 = peg$otherExpectation("\u6F22\u6570\u5B57"),
        peg$c209 = /^[\u4E00\u4E8C\u4E09\u56DB\u4E94\u516D\u4E03\u516B\u4E5D]/,
        peg$c210 = peg$classExpectation(["\u4E00", "\u4E8C", "\u4E09", "\u56DB", "\u4E94", "\u516D", "\u4E03", "\u516B", "\u4E5D"], false, false),
        peg$c211 = function(num) {return "一二三四五六七八九".indexOf(num)+1},
        peg$c212 = peg$otherExpectation("\u5168\u89D2\u6570\u5B57"),
        peg$c213 = /^[\uFF11\uFF12\uFF13\uFF14\uFF15\uFF16\uFF17\uFF18\uFF19]/,
        peg$c214 = peg$classExpectation(["\uFF11", "\uFF12", "\uFF13", "\uFF14", "\uFF15", "\uFF16", "\uFF17", "\uFF18", "\uFF19"], false, false),
        peg$c215 = function(num) {return "１２３４５６７８９".indexOf(num)+1},
        peg$c216 = peg$otherExpectation("\u30A2\u30EB\u30D5\u30A1\u30D9\u30C3\u30C8"),
        peg$c217 = /^[A-z]/,
        peg$c218 = peg$classExpectation([["A", "z"]], false, false),
        peg$c219 = peg$otherExpectation("Ascii\u6587\u5B57"),
        peg$c220 = /^[ -~]/,
        peg$c221 = peg$classExpectation([[" ", "~"]], false, false),
        peg$c222 = peg$otherExpectation("\u533A\u5207\u308A\u6587\u5B57"),
        peg$c223 = /^[\u2000-\u206F]/,
        peg$c224 = peg$classExpectation([["\u2000", "\u206F"]], false, false),
        peg$c225 = peg$otherExpectation("\u534A\u89D2\u6570\u5B57"),
        peg$c226 = /^[0-9]/,
        peg$c227 = peg$classExpectation([["0", "9"]], false, false),
        peg$c228 = peg$otherExpectation("\u6539\u884C"),
        peg$c229 = "\n",
        peg$c230 = peg$literalExpectation("\n", false),
        peg$c231 = function() {return {type: 'line-break',typeJP: '改行', location: location()}},
        peg$c232 = peg$otherExpectation("\u6F22\u5B57"),
        peg$c233 = /^[\u4E00-\u9FEA\u3400-\u4DFF]/,
        peg$c234 = peg$classExpectation([["\u4E00", "\u9FEA"], ["\u3400", "\u4DFF"]], false, false),
        peg$c235 = peg$otherExpectation("\u4EEE\u540D\u6587\u5B57"),
        peg$c236 = peg$otherExpectation("\u5E73\u4EEE\u540D"),
        peg$c237 = /^[\u3040-\u309F]/,
        peg$c238 = peg$classExpectation([["\u3040", "\u309F"]], false, false),
        peg$c239 = peg$otherExpectation("\u7247\u4EEE\u540D"),
        peg$c240 = /^[\u30A0-\u30FF\u31F0-\u31FF]/,
        peg$c241 = peg$classExpectation([["\u30A0", "\u30FF"], ["\u31F0", "\u31FF"]], false, false),
        peg$c242 = peg$otherExpectation("\u5909\u4F53\u4EEE\u540D"),
        peg$c243 = /^[\u1B000-\u1B000\u1B100-\u1B12F]/,
        peg$c244 = peg$classExpectation(["\u1B00", ["0", "\u1B00"], "0", "\u1B10", ["0", "\u1B12"], "F"], false, false),
        peg$c245 = peg$otherExpectation("\u6F22\u6587\u8A18\u53F7"),
        peg$c246 = /^[\u3190-\u319F]/,
        peg$c247 = peg$classExpectation([["\u3190", "\u319F"]], false, false),
        peg$c248 = peg$otherExpectation("\u8A18\u53F7"),
        peg$c249 = /^[\u3000-\u3007\u300C-\u300F\u3012-\u3013\u3016-\u303F]/,
        peg$c250 = peg$classExpectation([["\u3000", "\u3007"], ["\u300C", "\u300F"], ["\u3012", "\u3013"], ["\u3016", "\u303F"]], false, false),
        peg$c251 = peg$otherExpectation("\u5168\u89D2\u8A18\u53F7"),
        peg$c252 = /^[\uFF00-\uFF02\uFF04-\uFF07\uFF0B-\uFF19\uFF1B-\uFF3A\uFF3E-\uFF5A\uFF5E-\uFFEF]/,
        peg$c253 = peg$classExpectation([["\uFF00", "\uFF02"], ["\uFF04", "\uFF07"], ["\uFF0B", "\uFF19"], ["\uFF1B", "\uFF3A"], ["\uFF3E", "\uFF5A"], ["\uFF5E", "\uFFEF"]], false, false),

        peg$currPos          = 0,
        peg$savedPos         = 0,
        peg$posDetailsCache  = [{ line: 1, column: 1 }],
        peg$maxFailPos       = 0,
        peg$maxFailExpected  = [],
        peg$silentFails      = 0,

        peg$resultsCache = {},

        peg$result;

    if ("startRule" in options) {
      if (!(options.startRule in peg$startRuleFunctions)) {
        throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
      }

      peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }

    function text() {
      return input.substring(peg$savedPos, peg$currPos);
    }

    function location() {
      return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function expected(description, location) {
      location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

      throw peg$buildStructuredError(
        [peg$otherExpectation(description)],
        input.substring(peg$savedPos, peg$currPos),
        location
      );
    }

    function error(message, location) {
      location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

      throw peg$buildSimpleError(message, location);
    }

    function peg$literalExpectation(text, ignoreCase) {
      return { type: "literal", text: text, ignoreCase: ignoreCase };
    }

    function peg$classExpectation(parts, inverted, ignoreCase) {
      return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
    }

    function peg$anyExpectation() {
      return { type: "any" };
    }

    function peg$endExpectation() {
      return { type: "end" };
    }

    function peg$otherExpectation(description) {
      return { type: "other", description: description };
    }

    function peg$computePosDetails(pos) {
      var details = peg$posDetailsCache[pos], p;

      if (details) {
        return details;
      } else {
        p = pos - 1;
        while (!peg$posDetailsCache[p]) {
          p--;
        }

        details = peg$posDetailsCache[p];
        details = {
          line:   details.line,
          column: details.column
        };

        while (p < pos) {
          if (input.charCodeAt(p) === 10) {
            details.line++;
            details.column = 1;
          } else {
            details.column++;
          }

          p++;
        }

        peg$posDetailsCache[pos] = details;
        return details;
      }
    }

    function peg$computeLocation(startPos, endPos) {
      var startPosDetails = peg$computePosDetails(startPos),
          endPosDetails   = peg$computePosDetails(endPos);

      return {
        start: {
          offset: startPos,
          line:   startPosDetails.line,
          column: startPosDetails.column
        },
        end: {
          offset: endPos,
          line:   endPosDetails.line,
          column: endPosDetails.column
        }
      };
    }

    function peg$fail(expected) {
      if (peg$currPos < peg$maxFailPos) { return; }

      if (peg$currPos > peg$maxFailPos) {
        peg$maxFailPos = peg$currPos;
        peg$maxFailExpected = [];
      }

      peg$maxFailExpected.push(expected);
    }

    function peg$buildSimpleError(message, location) {
      return new peg$SyntaxError(message, null, null, location);
    }

    function peg$buildStructuredError(expected, found, location) {
      return new peg$SyntaxError(
        peg$SyntaxError.buildMessage(expected, found),
        expected,
        found,
        location
      );
    }

    function peg$parseDocument() {
      var s0, s1, s2;

      var key    = peg$currPos * 42 + 0,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseBlock();
      if (s2 === peg$FAILED) {
        s2 = peg$parseInline();
        if (s2 === peg$FAILED) {
          s2 = peg$parseSyntaxSugar();
          if (s2 === peg$FAILED) {
            s2 = peg$parseTextSegment();
            if (s2 === peg$FAILED) {
              s2 = peg$parseLb();
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parseBlock();
          if (s2 === peg$FAILED) {
            s2 = peg$parseInline();
            if (s2 === peg$FAILED) {
              s2 = peg$parseSyntaxSugar();
              if (s2 === peg$FAILED) {
                s2 = peg$parseTextSegment();
                if (s2 === peg$FAILED) {
                  s2 = peg$parseLb();
                }
              }
            }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1);
      }
      s0 = s1;

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSyntaxSugar() {
      var s0, s1;

      var key    = peg$currPos * 42 + 1,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      s0 = peg$currPos;
      s1 = peg$parseSFurigana();
      if (s1 === peg$FAILED) {
        s1 = peg$parseSIllegible();
        if (s1 === peg$FAILED) {
          s1 = peg$parseSBugHole();
          if (s1 === peg$FAILED) {
            s1 = peg$parseSAnnotation();
            if (s1 === peg$FAILED) {
              s1 = peg$parseSOkuri();
              if (s1 === peg$FAILED) {
                s1 = peg$parseSKaeri();
              }
            }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1(s1);
      }
      s0 = s1;

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSFurigana() {
      var s0, s1, s2, s3, s4, s5;

      var key    = peg$currPos * 42 + 2,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 65372) {
        s1 = peg$c2;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c3); }
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseKanji();
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseKanji();
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = [];
          s3 = peg$parseKana();
          if (s3 !== peg$FAILED) {
            while (s3 !== peg$FAILED) {
              s2.push(s3);
              s3 = peg$parseKana();
            }
          } else {
            s2 = peg$FAILED;
          }
          if (s2 === peg$FAILED) {
            s2 = [];
            s3 = peg$parseAlphabet();
            if (s3 !== peg$FAILED) {
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parseAlphabet();
              }
            } else {
              s2 = peg$FAILED;
            }
          }
        }
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 65288) {
            s3 = peg$c4;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c5); }
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$parseTextSegment();
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$parseTextSegment();
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 65289) {
                s5 = peg$c6;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c7); }
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c8(s2, s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSIllegible() {
      var s0, s1, s2;

      var key    = peg$currPos * 42 + 3,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      if (input.charCodeAt(peg$currPos) === 9632) {
        s2 = peg$c10;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c11); }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          if (input.charCodeAt(peg$currPos) === 9632) {
            s2 = peg$c10;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c11); }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c12(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c9); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSBugHole() {
      var s0, s1, s2;

      var key    = peg$currPos * 42 + 4,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      if (input.charCodeAt(peg$currPos) === 9633) {
        s2 = peg$c14;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c15); }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          if (input.charCodeAt(peg$currPos) === 9633) {
            s2 = peg$c14;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c15); }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c16(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c13); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSAnnotation() {
      var s0, s1, s2, s3;

      var key    = peg$currPos * 42 + 5,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 12304) {
        s1 = peg$c18;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c19); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseTextSegment();
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseTextSegment();
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 12305) {
            s3 = peg$c20;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c21); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c22(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c17); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSOkuri() {
      var s0, s1, s2, s3;

      var key    = peg$currPos * 42 + 6,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 65371) {
        s1 = peg$c24;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c25); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        if (peg$c26.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c27); }
        }
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            if (peg$c26.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c27); }
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 65373) {
            s3 = peg$c28;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c29); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c30(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c23); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSKaeri() {
      var s0, s1, s2, s3;

      var key    = peg$currPos * 42 + 7,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 12308) {
        s1 = peg$c32;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c33); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseTextSegment();
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseTextSegment();
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 12309) {
            s3 = peg$c34;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c35); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c36(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c31); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseBlock() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

      var key    = peg$currPos * 42 + 8,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 65339) {
        s1 = peg$c38;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c39); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseBlockName();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseAttrs();
          if (s3 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c40) {
              s4 = peg$c40;
              peg$currPos += 2;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c41); }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseBlockContent();
              if (s5 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c42) {
                  s6 = peg$c42;
                  peg$currPos += 2;
                } else {
                  s6 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c43); }
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseBlockName();
                  if (s7 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 65341) {
                      s8 = peg$c44;
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c45); }
                    }
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = peg$currPos;
                      s9 = peg$c46(s2, s3, s5, s7);
                      if (s9) {
                        s9 = void 0;
                      } else {
                        s9 = peg$FAILED;
                      }
                      if (s9 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c47(s2, s3, s5, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c37); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseBlockName() {
      var s0, s1;

      var key    = peg$currPos * 42 + 9,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$parseStructureBlockName();
      if (s0 === peg$FAILED) {
        s0 = peg$parseLayoutBlockName();
        if (s0 === peg$FAILED) {
          s0 = peg$parseGraphicBlockName();
          if (s0 === peg$FAILED) {
            s0 = peg$parsePoemBlockName();
          }
        }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c48); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseStructureBlockName() {
      var s0;

      var key    = peg$currPos * 42 + 10,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 2) === peg$c49) {
        s0 = peg$c49;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c50); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c51) {
          s0 = peg$c51;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c52); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c53) {
            s0 = peg$c53;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c54); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c55) {
              s0 = peg$c55;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c56); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c57) {
                s0 = peg$c57;
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c58); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c59) {
                  s0 = peg$c59;
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c60); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c61) {
                    s0 = peg$c61;
                    peg$currPos += 2;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c62); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c63) {
                      s0 = peg$c63;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c64); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c65) {
                        s0 = peg$c65;
                        peg$currPos += 2;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c66); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c67) {
                          s0 = peg$c67;
                          peg$currPos += 3;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c68); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c69) {
                            s0 = peg$c69;
                            peg$currPos += 2;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c70); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c71) {
                              s0 = peg$c71;
                              peg$currPos += 2;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c72); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c73) {
                                s0 = peg$c73;
                                peg$currPos += 2;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c74); }
                              }
                              if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c75) {
                                  s0 = peg$c75;
                                  peg$currPos += 3;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c76); }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parsePoemBlockName() {
      var s0;

      var key    = peg$currPos * 42 + 11,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 2) === peg$c77) {
        s0 = peg$c77;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c78); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c79) {
          s0 = peg$c79;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c80); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c81) {
            s0 = peg$c81;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c82); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c83) {
              s0 = peg$c83;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c84); }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseLayoutBlockName() {
      var s0;

      var key    = peg$currPos * 42 + 12,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 4) === peg$c85) {
        s0 = peg$c85;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c86); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c87) {
          s0 = peg$c87;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c88); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c89) {
            s0 = peg$c89;
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c90); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c91) {
              s0 = peg$c91;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c92); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c93) {
                s0 = peg$c93;
                peg$currPos += 4;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c94); }
              }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseGraphicBlockName() {
      var s0;

      var key    = peg$currPos * 42 + 13,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.charCodeAt(peg$currPos) === 34920) {
        s0 = peg$c95;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c96); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c97) {
          s0 = peg$c97;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c98); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c99) {
            s0 = peg$c99;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c100); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 32117) {
              s0 = peg$c101;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c102); }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseInline() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

      var key    = peg$currPos * 42 + 14,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 12298) {
        s1 = peg$c104;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c105); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseInlineName();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseAttrs();
          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 65306) {
              s4 = peg$c106;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c107); }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseInlineContent();
              if (s5 !== peg$FAILED) {
                s6 = [];
                s7 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 65372) {
                  s8 = peg$c2;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c3); }
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseInlineContent();
                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 65372) {
                    s8 = peg$c2;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c3); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInlineContent();
                    if (s9 !== peg$FAILED) {
                      s8 = [s8, s9];
                      s7 = s8;
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                }
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 12299) {
                    s7 = peg$c108;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c109); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c110(s2, s3, s5, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c103); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseInlineName() {
      var s0;

      var key    = peg$currPos * 42 + 15,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      s0 = peg$parseSemanticInlineName();
      if (s0 === peg$FAILED) {
        s0 = peg$parseContentInlineName();
        if (s0 === peg$FAILED) {
          s0 = peg$parseLayoutInlineName();
          if (s0 === peg$FAILED) {
            s0 = peg$parseSymbolInlineName();
            if (s0 === peg$FAILED) {
              s0 = peg$parseGraphicInlineName();
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSemanticInlineName() {
      var s0;

      var key    = peg$currPos * 42 + 16,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 2) === peg$c111) {
        s0 = peg$c111;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c112); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c113) {
          s0 = peg$c113;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c114); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c115) {
            s0 = peg$c115;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c116); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c117) {
              s0 = peg$c117;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c118); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c119) {
                s0 = peg$c119;
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c120); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c121) {
                  s0 = peg$c121;
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c122); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c123) {
                    s0 = peg$c123;
                    peg$currPos += 2;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c124); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c125) {
                      s0 = peg$c125;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c126); }
                    }
                  }
                }
              }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseContentInlineName() {
      var s0;

      var key    = peg$currPos * 42 + 17,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 3) === peg$c127) {
        s0 = peg$c127;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c128); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c129) {
          s0 = peg$c129;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c130); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 38988) {
            s0 = peg$c131;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c132); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c133) {
              s0 = peg$c133;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c134); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c135) {
                s0 = peg$c135;
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c136); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c137) {
                  s0 = peg$c137;
                  peg$currPos += 3;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c138); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c139) {
                    s0 = peg$c139;
                    peg$currPos += 2;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c140); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c141) {
                      s0 = peg$c141;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c142); }
                    }
                  }
                }
              }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseLayoutInlineName() {
      var s0;

      var key    = peg$currPos * 42 + 18,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 2) === peg$c143) {
        s0 = peg$c143;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c144); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c145) {
          s0 = peg$c145;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c146); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c147) {
            s0 = peg$c147;
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c148); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c149) {
              s0 = peg$c149;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c150); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c151) {
                s0 = peg$c151;
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c152); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c153) {
                  s0 = peg$c153;
                  peg$currPos += 3;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c154); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c155) {
                    s0 = peg$c155;
                    peg$currPos += 4;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c156); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c157) {
                      s0 = peg$c157;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c158); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c159) {
                        s0 = peg$c159;
                        peg$currPos += 2;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c160); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c161) {
                          s0 = peg$c161;
                          peg$currPos += 2;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c162); }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseSymbolInlineName() {
      var s0;

      var key    = peg$currPos * 42 + 19,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 3) === peg$c163) {
        s0 = peg$c163;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c164); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c165) {
          s0 = peg$c165;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c166); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c167) {
            s0 = peg$c167;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c168); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c169) {
              s0 = peg$c169;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c170); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c171) {
                s0 = peg$c171;
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c172); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c173) {
                  s0 = peg$c173;
                  peg$currPos += 4;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c174); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c175) {
                    s0 = peg$c175;
                    peg$currPos += 4;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c176); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c177) {
                      s0 = peg$c177;
                      peg$currPos += 4;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c178); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c179) {
                        s0 = peg$c179;
                        peg$currPos += 4;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c180); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c181) {
                          s0 = peg$c181;
                          peg$currPos += 4;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c182); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c183) {
                            s0 = peg$c183;
                            peg$currPos += 4;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c184); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c185) {
                              s0 = peg$c185;
                              peg$currPos += 3;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c186); }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseGraphicInlineName() {
      var s0;

      var key    = peg$currPos * 42 + 20,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      if (input.substr(peg$currPos, 2) === peg$c187) {
        s0 = peg$c187;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c188); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 21360) {
          s0 = peg$c189;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c190); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c191) {
            s0 = peg$c191;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c192); }
          }
        }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseInlineContent() {
      var s0, s1, s2;

      var key    = peg$currPos * 42 + 21,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseInline();
      if (s2 === peg$FAILED) {
        s2 = peg$parseSyntaxSugar();
        if (s2 === peg$FAILED) {
          s2 = peg$parseTextSegment();
          if (s2 === peg$FAILED) {
            s2 = peg$parseLb();
          }
        }
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseInline();
        if (s2 === peg$FAILED) {
          s2 = peg$parseSyntaxSugar();
          if (s2 === peg$FAILED) {
            s2 = peg$parseTextSegment();
            if (s2 === peg$FAILED) {
              s2 = peg$parseLb();
            }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c194(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c193); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseBlockContent() {
      var s0, s1, s2;

      var key    = peg$currPos * 42 + 22,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseBlock();
      if (s2 === peg$FAILED) {
        s2 = peg$parseInline();
        if (s2 === peg$FAILED) {
          s2 = peg$parseSyntaxSugar();
          if (s2 === peg$FAILED) {
            s2 = peg$parseTextSegment();
            if (s2 === peg$FAILED) {
              s2 = peg$parseLb();
            }
          }
        }
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseBlock();
        if (s2 === peg$FAILED) {
          s2 = peg$parseInline();
          if (s2 === peg$FAILED) {
            s2 = peg$parseSyntaxSugar();
            if (s2 === peg$FAILED) {
              s2 = peg$parseTextSegment();
              if (s2 === peg$FAILED) {
                s2 = peg$parseLb();
              }
            }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c194(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c195); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseTextSegment() {
      var s0, s1, s2;

      var key    = peg$currPos * 42 + 23,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseKanji();
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parseKanji();
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        s1 = [];
        s2 = peg$parseKana();
        if (s2 !== peg$FAILED) {
          while (s2 !== peg$FAILED) {
            s1.push(s2);
            s2 = peg$parseKana();
          }
        } else {
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = [];
          s2 = peg$parseAscii();
          if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
              s1.push(s2);
              s2 = peg$parseAscii();
            }
          } else {
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = [];
            s2 = peg$parseCJKSymbols();
            if (s2 !== peg$FAILED) {
              while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parseCJKSymbols();
              }
            } else {
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = [];
              s2 = peg$parseHalfwidthFullwidth();
              if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                  s1.push(s2);
                  s2 = peg$parseHalfwidthFullwidth();
                }
              } else {
                s1 = peg$FAILED;
              }
            }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c197(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c196); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseAttrs() {
      var s0, s1, s2, s3;

      var key    = peg$currPos * 42 + 24,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseId();
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseClass();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseClass();
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c199(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c198); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseId() {
      var s0, s1, s2, s3;

      var key    = peg$currPos * 42 + 25,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c201.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c202); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseAlphabet();
        if (s3 === peg$FAILED) {
          s3 = peg$parseDigit();
        }
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseAlphabet();
            if (s3 === peg$FAILED) {
              s3 = peg$parseDigit();
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c203(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c200); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseClass() {
      var s0, s1, s2, s3;

      var key    = peg$currPos * 42 + 26,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c205.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c206); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseAlphabet();
        if (s3 === peg$FAILED) {
          s3 = peg$parseDigit();
        }
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseAlphabet();
            if (s3 === peg$FAILED) {
              s3 = peg$parseDigit();
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c207(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c204); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseKanjiNum() {
      var s0, s1;

      var key    = peg$currPos * 42 + 27,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c209.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c210); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c211(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c208); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseFullwidthNum() {
      var s0, s1;

      var key    = peg$currPos * 42 + 28,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c213.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c214); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c215(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c212); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseAlphabet() {
      var s0, s1;

      var key    = peg$currPos * 42 + 29,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c217.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c218); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c216); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseAscii() {
      var s0, s1;

      var key    = peg$currPos * 42 + 30,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c220.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c221); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c219); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parsePunctuation() {
      var s0, s1;

      var key    = peg$currPos * 42 + 31,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c223.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c224); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c222); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseDigit() {
      var s0, s1;

      var key    = peg$currPos * 42 + 32,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c226.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c227); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c225); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseLb() {
      var s0, s1;

      var key    = peg$currPos * 42 + 33,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 10) {
        s1 = peg$c229;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c230); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c231();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c228); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseKanji() {
      var s0, s1;

      var key    = peg$currPos * 42 + 34,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c233.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c234); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c232); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseKana() {
      var s0, s1;

      var key    = peg$currPos * 42 + 35,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      s0 = peg$parseHiragana();
      if (s0 === peg$FAILED) {
        s0 = peg$parseKatakana();
        if (s0 === peg$FAILED) {
          s0 = peg$parseHentaikana();
        }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c235); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseHiragana() {
      var s0, s1;

      var key    = peg$currPos * 42 + 36,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c237.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c238); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c236); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseKatakana() {
      var s0, s1;

      var key    = peg$currPos * 42 + 37,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c240.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c239); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseHentaikana() {
      var s0, s1;

      var key    = peg$currPos * 42 + 38,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c243.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c244); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c242); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseKanbun() {
      var s0, s1;

      var key    = peg$currPos * 42 + 39,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c246.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c247); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c245); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseCJKSymbols() {
      var s0, s1;

      var key    = peg$currPos * 42 + 40,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c249.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c250); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c248); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }

    function peg$parseHalfwidthFullwidth() {
      var s0, s1;

      var key    = peg$currPos * 42 + 41,
          cached = peg$resultsCache[key];

      if (cached) {
        peg$currPos = cached.nextPos;

        return cached.result;
      }

      peg$silentFails++;
      if (peg$c252.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c253); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c251); }
      }

      peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

      return s0;
    }


    	function concatTextSegments(ary){
    	  let result = []
      	  let temp = ""
          let location = {}
    		ary.forEach(a =>{
    			if (a.type == 'segment'){
    				temp += a.value
                    if (!location.start) location.start = a.location.start
                    location.end = a.location.end
    			} else {
    				if(temp.length > 0) result.push({type: 'text', value: temp, typeJP: 'テキスト', location: location})
    				result.push(a)
    				temp = ""
                    location = {}
    			}})
    		if(temp.length > 0) result.push({type: 'text', value: temp, typeJP: 'テキスト',  location: location})
    		return result
    	}
    const inlineElems = {
      // 事項
      史料: {
        type: 'document'
      },
      人物: {
        type: 'person'
      },
      場所: {
        type: 'location'
      },
      地理: {
        type: 'geography'
      },
      建物: {
        type: 'building'
      },
      日時: {
        type: 'datetime'
      },
      事項: {
        type: 'seg'
      },
      数量: {
        type: 'measure',
      },
      差出人: {
        type: 'sender'
      },
      受取人: {
        type: 'recipient'
      },
       題: {
        type: 'title'
      },
      外題: {
        type: 'title',
        props: {position: 'cover'}
      },
      内題: {
        type: 'title',
        props: {position: 'inner'}
      },
      梵字: {
        type: 'sanskrit'
      },
      振り仮名: {
        type: 'furigana',
        values: ['content', 'kana']
      },
      迎え仮名: {
        type: 'mukaegana',
        values: ['content', 'kana']
      },
      闕字: {
        type: 'ketsuji'
      },
      見せ消ち: {
        type: 'misekechi',
        values: ['content', 'mark']
      },
      訂正: {
        type: 'correction',
        values: ['content', 'correction']
      },
      割書: {
        type: 'warigaki',
        values: ['right', 'left']
      },
      角書: {
        type: 'tsunogaki',
        values: ['right', 'left']
      },
      傍注: {
        type: 'sidenote',
        values: ['content', 'note']
      },
      勘返: {
        type: 'kanhen',
        values: ['content', 'reply']
      },
      ヲコト点: {
        type: 'okoto',
        values: ['content', 'annotation']
      },
       囲い書き: {
        type: 'box'
      },
      送り仮名: {
        type: 'okurigana'
      },
      返り点: {
        type: 'kaeriten'
      },
      音合符: {
        type: 'on-gofu'
      },
      訓合符: {
        type: 'kun-gofu'
      },
      朱引左単: {
        type: 'shubiki',
        props: {
          position: 'left',
          style: 'single'
        }
      },
      朱引右単: {
        type: 'shubiki',
        props: {
          position: 'right',
          style: 'single'
        }
      },
      朱引中単: {
        type: 'shubiki',
        props: {
          position: 'center',
          style: 'single'
        }
      },
      朱引左複: {
        type: 'shubiki',
        props: {
          position: 'left',
          style: 'double'
        }
      },
      朱引右複: {
        type: 'shubiki',
        props: {
          position: 'right',
          style: 'double'
        }
      },
      朱引中複: {
        type: 'shubiki',
        props: {
          position: 'center',
          style: 'double'
        }
      },
      朱引箱: {
        type: 'shubiki',
        props: {
          position: 'box',
          style: 'single'
        }
      },
      小書き: {
        type: 'small'
      },
      傍線: {
        type: 'sideline'
      },
      合点: {
        type: 'gatten',
        values: ['mark']
      },
      傍点: {
        type: 'emphasis',
        values: ['content', 'mark']
      },
      文字囲: {
        type: 'box'
      },
      花押: {
        type: 'written-seal'
      },
      印: {
        type: 'seal'
      },
      外字: {
        type: 'gaiji'
      }
    }

    const blockElements = {
      表紙: {
        type: 'front'
      },
      裏表紙: {
        type: 'back'
      },
      序文: {
        type: 'introduction'
      },
       跋文: {
        type: 'afterword'
      },
      章段: {
        type: 'section'
      },
       和歌: {
        type: 'waka'
      },
        俳句: {
        type: 'haiku'
      },
       漢文: {
        type: 'chinese-writing'
      },
       漢詩: {
        type: 'chinese-poem'
      },
       頭注: {
        type: 'headnote'
      },
      脚注: {
        type: 'footnote'
      },
      奥付: {
        type: 'colophon'
      },  
      裏書き: {
        type: 'reverse-side'
      },
      識語: {
        type: 'shikigo'
      }, 
      刊記: {
        type: 'kanki'
      },
      蔵書票: {
        type: 'bookplate'
      },  
      字下げ: {
        type: 'indent',
        props: {size: 1}
      },  
      字下げ一: {
        type: 'indent',
        props: {size: 1}
      },
      字下げ二: {
        type: 'indent',
        props: {size: 2}
      },
      字下げ三: {
        type: 'indent',
        props: {size: 3}
      },
      字下げ四: {
        type: 'indent',
        props: {size: 4}
      },
      字下げ五: {
        type: 'indent',
        props: {size: 5}
      },
      字下げ六: {
        type: 'indent',
        props: {size: 6}
      },
      貼紙: {
        type: 'sticker'
      },
      封面: {
      	type: 'fumen'
      },
      裏書き: {
        type: 'endorsement'
      },
      紙背: {
        type: 'reverse-side'
      },
      表: {
        type: 'table'
      },  
      地図: {
        type: 'map'
      },  
      絵: {
        type: 'graphic'
      },
      系図: {
        type: 'family-tree'
      }
    }

    function blockAST(name, attrs, content) {
      let elm = blockElements[name]
      let ast = {
        type: elm.type,
        content: content,
        location: location()
      }
      if (elm.props) {
        Object.keys(elm.props).forEach(key => (ast[key] = elm.props[key]))
      }
      if (Object.keys(attrs).length > 0) {
        ast.attrs = attrs
      }
      ast.typeJP = name.trim()
      return ast
    }

    function inlineAST(name, attrs, content, extra) {
      let elm = inlineElems[name]
      let ast = {
        type: elm.type,
        location: location()
      }
      if (elm.props) {
        Object.keys(elm.props).forEach(key => (ast[key] = elm.props[key]))
      }
      if (Object.keys(attrs).length > 0) {
        ast.attrs = attrs
      }
      if (elm.values) {
        ast[elm.values[0]] = content
        for (let i = 1; i < elm.values.length; i++) {
          console.log(extra[i])
          ast[elm.values[i]] = extra[i-1]
        }
      } else {
        ast.content = content
      }
      ast.typeJP = name.trim()
      return ast
    }




    peg$result = peg$startRuleFunction();

    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
      return peg$result;
    } else {
      if (peg$result !== peg$FAILED && peg$currPos < input.length) {
        peg$fail(peg$endExpectation());
      }

      throw peg$buildStructuredError(
        peg$maxFailExpected,
        peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
        peg$maxFailPos < input.length
          ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
          : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
      );
    }
  }

  return {
    SyntaxError: peg$SyntaxError,
    parse:       peg$parse
  };
})();
