"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Popup = /** @class */ (function () {
    function Popup(parent, $el) {
        this.parent = parent;
        this.$el = $el;
        this.$el.style.position = 'abosolute';
        this.observeSelection();
        this.observeScroll();
    }
    Popup.prototype.observeSelection = function () {
        var _this = this;
        var doc = this.parent.doc;
        doc.on('selection-changed', function () {
            _this.update();
        });
    };
    Popup.prototype.observeScroll = function () {
        var _this = this;
        var editorState = this.parent.editorState;
        editorState.on('scroll', function () { return _this.hide(); });
    };
    Popup.prototype.showError = function (error) {
        var charEl = (this.parent.sourcePanel.getCharElAt(error.start));
        if (charEl) {
            var rect = charEl.getBoundingClientRect();
            console.log(rect);
            this.$el.className = 'popup error';
            this.message = error.message;
            this.showAt(rect);
        }
    };
    Popup.prototype.show = function () {
        this.$el.style.display = 'block';
        this.$el.innerHTML = this.message;
    };
    Popup.prototype.showAt = function (rect) {
        var parentWidth = this.$el.parentElement.clientWidth;
        if (rect.left < 0.7 * parentWidth) {
            this.$el.style.left = rect.right + 'px';
            this.$el.style.right = null;
            this.$el.style.top = rect.top + 'px';
        }
        else {
            this.$el.style.left = null;
            this.$el.style.right = window.outerWidth - rect.left + 'px';
            this.$el.style.top = rect.top + 'px';
        }
        this.show();
    };
    Popup.prototype.hide = function () {
        this.$el.style.display = 'none';
    };
    Popup.prototype.update = function () {
        var doc = this.parent.doc;
        if (doc.selStart !== doc.selEnd) {
            this.hide();
        }
        else {
            var start = doc.selStart;
            var err = doc.getErrorAt(start) || doc.getErrorAt(start - 1);
            if (err) {
                this.showError(err);
            }
            else {
                this.hide();
            }
        }
    };
    return Popup;
}());
exports.default = Popup;
