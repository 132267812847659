"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Tokenizer_1 = require("../Tokenizer");
var KojiTokenizer = /** @class */ (function (_super) {
    __extends(KojiTokenizer, _super);
    function KojiTokenizer(src) {
        var _this = _super.call(this, src) || this;
        _this.tokenIndex = 0;
        _this.brackets = {
            '（': 'FURIGANA_START',
            '）': 'FURIGANA_END',
            '｛': 'KAERI_START',
            '｝': 'KAERI_END',
            '〔': 'OKURIGANA_START',
            '〕': 'OKURIGANA_END',
            '【': 'ANNO_START',
            '】': 'ANNO_END'
        };
        _this.state = {
            brackets: [],
            errors: [],
            inInlinePre: false,
            inInlineBody: false,
            inBlockTag: false,
            inFurigana: false,
            inKaeriten: false,
            inOkurigana: false,
            inAnno: false
        };
        return _this;
    }
    KojiTokenizer.prototype.isBracket = function (ch) {
        return '（）｛｝【】〔〕'.indexOf(ch) > -1;
    };
    KojiTokenizer.prototype.isColon = function (ch) {
        return ch === '：';
    };
    KojiTokenizer.prototype.isLetter = function (ch) {
        var regex = [
            /[\u0020-\u007E]/,
            /[\u2000-\u206F]/,
            /[\u4E00-\u9FEA\u3400-\u4DFF]/,
            /[\u3040-\u309F]/,
            /[\u30A0-\u30FF\u31F0-\u31FF]/,
            /[\u1B000-\u1B000\u1B100-\u1B12F]/,
            /[\u3190-\u319F]/,
            /[\u3000-\u3007\u300C-\u300F\u3012-\u3013\u3016-\u303F]/,
            /[\uFF00-\uFF02\uFF04-\uFF07\uFF0B-\uFF0E\uFF10-\uFF19\uFF1B-\uFF3A\uFF3E-\uFF5A\uFF5E-\uFFEF]/
        ];
        return regex.some(function (r) { return ch.match(r) != null; });
    };
    KojiTokenizer.prototype.isMatchingPair = function (ch1, ch2) {
        return ((ch1 === '《' && ch2 === '》') ||
            (ch1 === '（' && ch2 === '）') ||
            (ch1 === '｛' && ch2 === '｝') ||
            (ch1 === '〔' && ch2 === '〕') ||
            (ch1 === '［' && ch2 === '］') ||
            (ch1 === '［／' && ch2 === '］') ||
            (ch1 === '【' && ch2 === '】'));
    };
    KojiTokenizer.prototype.readWhile = function (predicate) {
        var buf = '';
        while (!this.is.eof() && predicate(this.is.peek()))
            buf += this.is.next();
        return buf;
    };
    KojiTokenizer.prototype.readTextSegment = function () {
        var seg = this.readWhile(this.isLetter);
        if (this.state.inInlinePre) {
            return { type: 'INLINE_NAME', value: name };
        }
        else if (this.state.inBlockTag) {
            return { type: 'BLOCK_NAME', value: name };
        }
        else if (this.state.inFurigana) {
            return { type: 'FURIGANA', value: seg };
        }
        else if (this.state.inKaeriten) {
            return { type: 'KAERITEN', value: seg };
        }
        else if (this.state.inOkurigana) {
            return { type: 'OKURIGANA', value: seg };
        }
        else if (this.state.inAnno) {
            return { type: 'ANNOTATION', value: seg };
        }
        else {
            return { type: 'TEXT_SEGMENT', value: seg };
        }
    };
    KojiTokenizer.prototype.readBracket = function () {
        var ch = this.is.next();
        var tok;
        if ('｛（【〔'.indexOf(ch) > -1) {
            tok = { type: this.brackets[ch], value: ch };
            this.state.brackets.push(tok);
            if (ch == '（')
                this.state.inFurigana = true;
            if (ch == '｛')
                this.state.inKaeriten = true;
            if (ch == '〔')
                this.state.inOkurigana = true;
            if (ch == '【')
                this.state.inAnno = true;
        }
        else {
            tok = { type: this.brackets[ch], value: ch };
            var pair = this.state.brackets[this.state.brackets.length - 1];
            if (pair && this.isMatchingPair(pair.value, ch)) {
                this.state.brackets.pop();
                pair.pairIndex = this.tokenIndex;
                tok.pairIndex = pair.tokenIndex;
            }
            else {
                this.state.brackets.push(tok);
                tok.error = true;
                tok.errorMsg = '対応する括弧がありません．';
            }
            if (ch == '）')
                this.state.inFurigana = false;
            if (ch == '｝')
                this.state.inKaeriten = false;
            if (ch == '〕')
                this.state.inOkurigana = false;
            if (ch == '】')
                this.state.inAnno = false;
        }
        return tok;
    };
    KojiTokenizer.prototype.readInlineStart = function () {
        var ch = this.is.next();
        this.state.inInlinePre = true;
        var tok = { type: 'INLINE_START', value: ch };
        this.state.brackets.push(tok);
        return tok;
    };
    KojiTokenizer.prototype.readInlineEnd = function () {
        var ch = this.is.next();
        var tok = { type: 'INLINE_END', value: ch };
        this.state.inInlinePre = false;
        this.state.inInlineBody = false;
        var last = this.state.brackets[this.state.brackets.length - 1];
        if (last && last.type === 'INLINE_START') {
            this.state.brackets.pop();
            last.pairIndex = this.tokenIndex;
            tok.pairIndex = last.tokenIndex;
        }
        else {
            tok.error = true;
            tok.errorMsg = '対応する括弧がありません．';
        }
        return tok;
    };
    KojiTokenizer.prototype.readBlockStart = function () {
        var ch = this.is.next();
        var tok;
        this.state.inBlockTag = true;
        if (this.is.peek() === '／') {
            tok = { type: 'BLOCK_CLOSING_START', value: ch + this.is.next() };
        }
        else {
            tok = { type: 'BLOCK_START', value: ch };
        }
        this.state.brackets.push(tok);
        return tok;
    };
    KojiTokenizer.prototype.readBlockEnd = function () {
        var ch = this.is.next();
        this.state.inBlockTag = false;
        var tok = { type: 'BLOCK_END', value: ch };
        var last = this.state.brackets[this.state.brackets.length - 1];
        if (last &&
            (last.type === 'BLOCK_START' || last.type === 'BLOCK_CLOSING_START')) {
            this.state.brackets.pop();
            last.pairIndex = this.tokenIndex;
            tok.pairIndex = last.tokenIndex;
        }
        else {
            tok.error = true;
            tok.errorMsg = '対応する括弧がありません．';
        }
        return tok;
    };
    KojiTokenizer.prototype.readColon = function () {
        if (this.state.inInlinePre) {
            this.state.inInlinePre = false;
            this.state.inInlineBody = true;
        }
        return { type: 'COLON', value: this.is.next() };
    };
    KojiTokenizer.prototype.readSep = function () {
        if (this.state.inInlineBody) {
            return { type: 'INLINE_SEP', value: this.is.next() };
        }
        else {
            return { type: 'FURIGANA_SEP', value: this.is.next() };
        }
    };
    KojiTokenizer.prototype.readLineBreak = function () {
        var ch = this.is.next();
        if (this.state.inInlinePre ||
            this.state.inBlockTag ||
            this.state.inKaeriten ||
            this.state.inOkurigana) {
            return {
                type: 'LB',
                value: ch,
                error: true,
                errorMsg: 'ここに改行を含めることはできません。'
            };
        }
        else {
            return { type: 'LB', value: ch, error: false };
        }
    };
    KojiTokenizer.prototype.readNext = function () {
        if (this.is.eof())
            return null;
        var ch = this.is.peek();
        var startPos = this.is.pos;
        var tok;
        if (ch === '《')
            tok = this.readInlineStart();
        if (ch === '》')
            tok = this.readInlineEnd();
        if (ch === '［')
            tok = this.readBlockStart();
        if (ch === '］')
            tok = this.readBlockEnd();
        if (ch === '｜')
            tok = this.readSep();
        if (this.isLetter(ch))
            tok = this.readTextSegment();
        if (this.isBracket(ch))
            tok = this.readBracket();
        if (this.isColon(ch))
            tok = this.readColon();
        if (ch === '\n')
            tok = this.readLineBreak();
        if (!tok) {
            // handle unknown characters
            ch = this.is.next();
            tok = {
                type: 'UNKNOWN',
                value: ch,
                error: true,
                errorMsg: "\u4E0D\u6B63\u306A\u6587\u5B57\uFF1A" + ch
            };
        }
        tok.start = startPos;
        tok.end = this.is.pos;
        tok.tokenIndex = this.tokenIndex++;
        return tok;
    };
    KojiTokenizer.prototype.tokenize = function () {
        var buf = [];
        while (this.peek()) {
            buf.push(this.next());
        }
        // mark pair errors
        this.state.brackets.forEach(function (p) {
            p.error = true;
            p.errorMsg = '対応する括弧がありません．';
        });
        return buf;
    };
    return KojiTokenizer;
}(Tokenizer_1.default));
exports.default = KojiTokenizer;
