"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var diff = require("virtual-dom/diff");
var patch = require("virtual-dom/patch");
var createElement = require("virtual-dom/create-element");
var h = require("virtual-dom/h");
var SourcePanel = /** @class */ (function () {
    function SourcePanel(parent, $el) {
        this.parent = parent;
        this.doc = parent.doc;
        this.$el = $el;
        // init
        this.vtree = this.render();
        var $newEl = createElement(this.vtree);
        this.$el.parentElement.replaceChild($newEl, this.$el);
        this.$el = $newEl;
        this.observeDoc();
        this.observeEditor();
    }
    SourcePanel.prototype.observeDoc = function () {
        var _this = this;
        this.doc.on('updated selection-changed', function (d) {
            _this.update();
        });
    };
    SourcePanel.prototype.observeEditor = function () {
        var _this = this;
        this.parent.editorState.on('resize', function () {
            var edt = _this.parent.editorState;
            // this.$el.style.width = edt.widthPx();
            _this.$el.style.height = edt.height + 'px';
        });
    };
    SourcePanel.prototype.scrollBy = function (x, y) {
        this.$el.scrollBy(x, y);
    };
    SourcePanel.prototype.scrollTo = function (x, y) {
        this.$el.scrollTo(x, y);
    };
    SourcePanel.prototype.charElArray = function () {
        var chars = this.$el.getElementsByClassName('char');
        var ary = [];
        for (var i = 0; i < chars.length; i++) {
            var char = chars[i];
            ary.push(char);
        }
        return ary;
    };
    // TODO: FIX THIS!
    SourcePanel.prototype.findVisuallyNearestCharInPrevLine = function (x, y) {
        var lineWidth = 24;
        var candidates = [];
        var chars = this.$el.getElementsByClassName('char');
        for (var index = 0; index < chars.length; index++) {
            var char = chars[index];
            if (char.getBoundingClientRect().left > x)
                candidates.push(char);
        }
        var nearest;
        candidates.reverse().forEach(function (c) {
            nearest = nearest || c;
            var cTop = c.getBoundingClientRect().top;
            var nTop = nearest.getBoundingClientRect().top;
            if (Math.abs(cTop - y) < Math.abs(nTop - y))
                nearest = c;
        });
        return nearest;
    };
    // TODO: FIX THIS!
    SourcePanel.prototype.findVisuallyNearestCharInNextLine = function (x, y) {
        var lineWidth = 24;
        var candidates = [];
        var chars = this.$el.getElementsByClassName('char');
        for (var index = 0; index < chars.length; index++) {
            var char = chars[index];
            if (char.getBoundingClientRect().left < x)
                candidates.push(char);
        }
        var nearest;
        candidates.forEach(function (c) {
            nearest = nearest || c;
            var cTop = c.getBoundingClientRect().top;
            var nTop = nearest.getBoundingClientRect().top;
            if (Math.abs(cTop - y) < Math.abs(nTop - y))
                nearest = c;
        });
        return nearest;
    };
    SourcePanel.prototype.currentLineEl = function () {
        var charEl = this.$el.getElementsByClassName('char')[this.doc.selStart];
        if (charEl) {
            return charEl.parentElement;
        }
        else {
            var lines = this.$el.getElementsByClassName('line');
            return lines[lines.length - 1];
        }
    };
    SourcePanel.prototype.currentCaretEl = function () {
        var charEl = this.$el.getElementsByClassName('char')[this.doc.selStart];
        if (charEl) {
            return charEl;
        }
        else {
            var lines = this.$el.getElementsByClassName('line');
            var lineEl = lines[lines.length - 1];
            return lineEl;
        }
    };
    SourcePanel.prototype.getCharElAt = function (index) {
        return this.$el.getElementsByClassName('char')[index];
    };
    SourcePanel.prototype.render = function () {
        var lines = this.doc.getLines();
        var lineDivs = lines.map(function (l, linenum) {
            var chars = l.map(function (c) {
                return h('span.char', { key: "char-" + c.index, className: c.classList.join(' ') }, c.char);
            });
            var hasError = chars.some(function (c) {
                return c.properties.className.indexOf('error') > 0;
            });
            return h("div.line", { key: "line-" + linenum, className: hasError ? 'error' : '' }, chars);
        });
        var linenumDiv = h('div.linenum', { key: 'linenum' }, '');
        lineDivs.unshift(linenumDiv);
        return h('div.source-panel', { key: 'root' }, lineDivs);
    };
    SourcePanel.prototype.update = function () {
        var start = Date.now();
        var newTree = this.render();
        var patches = diff(this.vtree, newTree);
        patch(this.$el, patches);
        var end = Date.now();
        this.vtree = newTree;
        // console.log('render: ', end - start, ' ms.');
    };
    return SourcePanel;
}());
exports.default = SourcePanel;
