"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function convertToHTML(ast) {
    return convert(ast);
}
exports.convertToHTML = convertToHTML;
var semantic = 'document person location geography building datetime seg measure sender recipient'.split(' ');
var character = 'sanskrit ketsuji sidenote kanhen'.split(' ');
var kanbun = 'okoto okurigana on-gofu kun-gohu shubiki small sideline box written-seal seal gaiji'.split(' ');
var block = 'front back introduction afterword section headnote colophon reverse-side shikigo kanki bookplate sticker fumen endorsement reverse-side table map graphic family-tree waka haiku chinese-writing chinese-poem';
function processSimpleInline(node) {
    var children = node.content.map(convert).join('');
    return "<span class=\"" + node.type + " inline\" data-tippy-arrow=\"true\" data-tippy-placement=\"right\" data-tippy-content=\"" + node.typeJP + "\">" + children + "</span>";
}
function processBlock(node) {
    var children = node.content.map(convert).join('');
    return "<div class=\"" + node.type + " block\">" + children + "</div>";
}
function isTextNode(node) {
    return node.type === 'text';
}
function convert(node) {
    if (node.type === 'root') {
        var children = node.content.map(convert).join('');
        return "<div class=\"koji root\">" + children + "</div>";
    }
    else if (semantic.indexOf(node.type) > -1) {
        return processSimpleInline(node);
    }
    else if (character.indexOf(node.type) > -1) {
        return processSimpleInline(node);
    }
    else if (kanbun.indexOf(node.type) > -1) {
        return processSimpleInline(node);
    }
    else if (isTextNode(node)) {
        return node.value;
    }
    else if (node.type === 'comment') {
        return processSimpleInline(node);
    }
    else if (node.type === 'line-break') {
        return '<br/>';
    }
    else if (node.type === 'title') {
        var children = node.content.map(convert).join('');
        return "<h2 class=\"" + node.type + "\">" + children + "</h2>";
    }
    else if (node.type === 'furigana') {
        var children = node.content.map(convert).join('');
        var kana = node.kana.map(convert).join('');
        return "<ruby class=\"" + node.type + "\">" + children + "<rt>" + kana + "</rt></ruby>";
    }
    else if (node.type === 'mukaegana') {
        var children = node.content.map(convert).join('');
        var kana = node.kana.map(convert).join('');
        return "<ruby class=\"" + node.type + "\">" + children + "<rt>" + kana + "</rt></ruby>";
    }
    else if (node.type === 'kaeriten') {
        var mark = node.mark; // fix this
        return "<span class=\"" + node.type + " inline\" >" + mark + "</span>";
    }
    else if (node.type === 'misekechi') {
        var children = node.content.map(convert).join('');
        var mark = node.mark.map(convert).join('');
        var correction = node.correction.map(convert).join('');
        return "<span class=\"" + node.type + "\">\n                <ruby>" + children + "\n                    <rt>" + correction + "</rt>\n                    <rtc>" + mark + "</rtc>\n                </ruby>\n            </span>";
    }
    else if (node.type === 'correction') {
        var children = node.content.map(convert).join('');
        var correction = node.correction.map(convert).join('');
        return "<ruby class=\"" + node.type + "\">" + children + "<rt>" + correction + "</rt></ruby>";
    }
    else if (node.type === 'warigaki') {
        var left = node.left.map(convert).join('');
        var right = node.right.map(convert).join('');
        return "<span class=\"" + node.type + "\">\n                <span class=\"warigaki-left\">" + left + "</span>\n                <span class=\"warigaki-right\">" + right + "</span>                \n            </span>";
    }
    else if (node.type === 'tsunogaki') {
        var left = node.left.map(convert).join('');
        var right = node.right.map(convert).join('');
        return "<span class=\"" + node.type + "\">\n                <span class=\"right\">" + right + "</span>\n                <span class=\"left\">" + left + "</span>\n            </span>";
    }
    else if (node.type === 'okoto') {
        return processSimpleInline(node);
    }
    else if (node.type === 'shubiki') {
        var children = node.content.map(convert).join('');
        return "<span class=\"" + node.type + " " + node.position + " " + node.style + "\">" + children + "</span>";
    }
    else if (node.type === 'gatten') {
        var mark = node.mark.map(convert).join('');
        return "<span class=\"" + node.type + "\">" + mark + "</span>";
    }
    else if (node.type === 'emphasis') {
        var children = node.content.map(convert).join('');
        var mark = node.mark.map(convert).join('');
        return "<span class=\"" + node.type + "\" style=\"text-emphasis-style:'" + mark + "'\">" + children + "</span>";
    }
    else if (block.indexOf(node.type) > -1) {
        return processBlock(node);
    }
    else if (node.type === 'indent') {
        var children = node.content.map(convert).join('');
        var size = node.size;
        return "<div class=\"" + node.type + "\" style=\"margin-top: " + size + "em\">" + children + "</div>";
    }
    else {
        throw Error('unknown type: ' + JSON.stringify(node));
    }
}
