"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Document_1 = require("../model/Document");
var SourcePanel_1 = require("./SourcePanel");
var Observable_1 = require("../utils/Observable");
var CaretPanel_1 = require("./CaretPanel");
var HiddenTextArea_1 = require("./HiddenTextArea");
var EditorState_1 = require("../model/EditorState");
var Display_1 = require("./Display");
var mode_1 = require("../mode");
var CaretPos_1 = require("../model/CaretPos");
var Popup_1 = require("./Popup");
var ActionMenu_1 = require("./ActionMenu");
var Wabun = /** @class */ (function () {
    function Wabun($container, options) {
        this.handlers = {};
        this.setMode(options.mode);
        // get container and its size
        this.$container = $container;
        // init models
        this.doc = new Document_1.default(this.mode);
        this.editorState = new EditorState_1.default();
        this.caret = new CaretPos_1.default(this);
        // build doms
        this.$root = document.createElement('div');
        this.$root.classList.add('wabun-edit');
        this.$root.style.width = this.editorState.width + 'px';
        this.$root.style.height = this.editorState.height + 'px';
        var $textarea = document.createElement('textarea');
        $textarea.classList.add('editor');
        $textarea.value = options.value || '';
        var $display = document.createElement('div');
        $display.classList.add('display');
        var $sourcePanel = document.createElement('div');
        $sourcePanel.classList.add('source-panel');
        var $caretPanel = document.createElement('div');
        $caretPanel.classList.add('caret-panel');
        var $caret = document.createElement('span');
        $caret.classList.add('caret');
        var $popup = document.createElement('div');
        $popup.classList.add('popup');
        var $actionMenu = document.createElement('div');
        $actionMenu.classList.add('action-menu');
        this.$root.appendChild($textarea);
        this.$root.appendChild($display);
        $display.appendChild($sourcePanel);
        $display.appendChild($caretPanel);
        $display.appendChild($popup);
        $display.appendChild($actionMenu);
        $caretPanel.appendChild($caret);
        // put the root dom in container
        this.$container.innerHTML = '';
        this.$container.appendChild(this.$root);
        // create components from doms
        this.display = new Display_1.default(this, $display);
        this.sourcePanel = new SourcePanel_1.default(this, $sourcePanel);
        this.caretPanel = new CaretPanel_1.CaretPanel(this, $caretPanel);
        this.textArea = new HiddenTextArea_1.default(this, $textarea);
        this.popup = new Popup_1.default(this, $popup);
        this.actionMenu = new ActionMenu_1.default(this, $actionMenu);
        // update model
        this.doc.updateSource(options.value);
        // start observers
        this.observeEditor();
        this.observeDoc();
        // set editor size
        var rect = this.$container.getBoundingClientRect();
        this.editorState.setSize(rect.width, rect.height - 10);
        this.display.syncScroll();
    }
    Wabun.prototype.setMode = function (name) {
        this.mode = mode_1.default[name] || mode_1.default['koji'];
    };
    Wabun.prototype.observeEditor = function () {
        var _this = this;
        this.editorState.on('resize', function () {
            _this.$root.style.width = _this.editorState.widthPx();
            _this.$root.style.height = _this.editorState.heightPx();
        });
    };
    Wabun.prototype.observeDoc = function () {
        var _this = this;
        this.doc.on('updated', function () {
            _this.emit('updated');
        });
    };
    Wabun.prototype.addClass = function (className, from, to) {
        this.doc.addClass(className, from, to);
    };
    Wabun.prototype.removeClass = function (className, from, to) {
        this.doc.removeClass(className, from, to);
    };
    Object.defineProperty(Wabun.prototype, "value", {
        get: function () {
            return this.doc.src;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Wabun.prototype, "ast", {
        get: function () {
            return this.doc.ast;
        },
        enumerable: true,
        configurable: true
    });
    return Wabun;
}());
exports.default = Wabun;
Observable_1.applyMixins(Wabun, [Observable_1.Observable]);
