"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable_1 = require("../utils/Observable");
// singleton
var Document = /** @class */ (function () {
    function Document(mode) {
        var _this = this;
        this.selStart = 0;
        this.selEnd = 0;
        this.errors = [];
        this.handlers = {};
        this.chars = [];
        this.tokens = [];
        this.mode = mode;
        this.on('selection-changed', function () {
            _this.highlightPair();
            _this.highlightToken();
        });
    }
    Document.prototype.updateSource = function (src) {
        var start = Date.now();
        if (this.src !== src) {
            this.src = src;
            var linenum_1 = 0;
            this.chars = src.split('').map(function (c, i) {
                var char = {
                    index: i,
                    char: c,
                    linenum: linenum_1,
                    classList: []
                };
                if (c === '\n') {
                    linenum_1++;
                    char.classList.push('lb');
                }
                if (c === '　') {
                    char.char = '□';
                    char.classList.push('zenkaku-space');
                }
                return char;
            });
            this.errors = [];
            this.tokens = this.tokenize();
            this.ast = this.parse();
            this.applySyntaxHighlight();
            this.emit('updated');
        }
    };
    Document.prototype.highlightPair = function () {
        this.removeClass('outline', 0, this.chars.length - 1);
        if (this.selStart !== this.selEnd)
            return;
        var next = this.chars[this.selStart];
        var prev = this.chars[this.selStart - 1];
        var nextTok = this.getTokenOf(next);
        var prevTok = this.getTokenOf(prev);
        var target;
        if (nextTok && nextTok.pairIndex !== undefined) {
            target = nextTok;
        }
        else if (prevTok && prevTok.pairIndex !== undefined) {
            target = prevTok;
        }
        if (target) {
            var pair = this.tokens[target.pairIndex];
            this.addClass('outline', target.start, target.end);
            this.addClass('outline', pair.start, pair.end);
        }
    };
    Document.prototype.highlightToken = function () {
        this.removeClass('focus', 0, this.chars.length - 1);
        if (this.selStart !== this.selEnd)
            return;
        var next = this.chars[this.selStart];
        var prev = this.chars[this.selStart - 1];
        var nextTok = this.getTokenOf(next);
        var prevTok = this.getTokenOf(prev);
        var target;
        if (nextTok && nextTok.type === 'INLINE_NAME') {
            target = nextTok;
        }
        else if (prevTok && prevTok.type === 'INLINE_NAME') {
            target = prevTok;
        }
        if (target) {
            this.addClass('focus', target.start, target.end);
        }
    };
    Document.prototype.tokenize = function () {
        var _this = this;
        var tokenizer = new this.mode.tokenizer(this.src);
        var tokens = tokenizer.tokenize();
        tokens.forEach(function (t) {
            if (t.error) {
                _this.errors.push({ start: t.start, end: t.end, message: t.errorMsg });
            }
        });
        return tokens;
    };
    Document.prototype.parse = function () {
        try {
            var ast = this.mode.parse(this.src);
            return ast;
        }
        catch (e) {
            console.error(e.message);
            var start = e.location.start.offset;
            var end = e.location.end.offset;
            console.error(start, end);
            this.errors.push({ start: start, end: end, message: e.message });
            this.addClass('error', start, end);
        }
        var timeout = setTimeout(function () { }, 1000);
    };
    Document.prototype.applySyntaxHighlight = function () {
        var _this = this;
        var map = this.mode.tokenClassMap;
        this.tokens.forEach(function (token) {
            var className = map[token.type];
            for (var i = token.start; i < token.end; i++) {
                var ch = _this.chars[i];
                ch.classList.push(className);
            }
            if (token.error)
                _this.addClass('error', token.start, token.end);
        });
    };
    Document.prototype.getTokenOf = function (char) {
        if (!char)
            return null;
        var res;
        this.tokens.forEach(function (t) {
            if (char.index >= t.start && char.index <= t.end)
                return (res = t);
        });
        return res;
    };
    Document.prototype.getCurrentToken = function () {
        if (this.hasSelection())
            return null;
        return this.getTokenOf(this.chars[this.selStart]);
    };
    Document.prototype.getErrorAt = function (index) {
        var error;
        this.errors.forEach(function (e) {
            if (index >= e.start && index < e.end)
                error = e;
        });
        return error;
    };
    Document.prototype.getLines = function () {
        var lines = [];
        // make sure there is at least one line in the doc
        lines.push([]);
        this.chars.forEach(function (c) {
            lines[lines.length - 1].push(c);
            if (c.char === '\n')
                lines.push([]);
        });
        return lines;
    };
    Document.prototype.getCurrentLineNum = function () {
        if (this.chars.length === 0)
            return 0;
        var nextChar = this.chars[this.selStart];
        if (nextChar) {
            return nextChar.linenum;
        }
        else {
            var prevChar = this.chars[this.selStart - 1];
            return prevChar.linenum;
        }
    };
    Document.prototype.addClass = function (className, start, end) {
        // console.log(start, end);
        for (var i = start; i < end; i++) {
            if (this.chars[i])
                this.chars[i].classList.push(className);
        }
        this.emit('updated');
        this.emit('class-changed');
    };
    Document.prototype.removeClass = function (className, start, end) {
        for (var i = start; i <= end; i++) {
            var index = this.chars[i].classList.indexOf(className);
            if (index !== -1)
                this.chars[i].classList.splice(index, 1);
        }
        this.emit('class-changed');
    };
    Document.prototype.removeAllClass = function (start, end) {
        for (var i = start; i < end; i++) {
            this.chars[i].classList = [];
        }
        this.emit('class-changed');
    };
    Document.prototype.resetClass = function () {
        this.chars.forEach(function (c) { return (c.classList = []); });
        this.emit('class-changed');
    };
    Document.prototype.setCaretPosition = function (start) {
        this.setSelection(start, start);
    };
    Document.prototype.setSelection = function (start, end) {
        if (this.selStart !== start || this.selEnd !== end) {
            this.selStart = start;
            this.selEnd = end;
            this.clearSelection();
            if (this.selStart !== this.selEnd) {
                this.addClass('selected', this.selStart, this.selEnd);
            }
            this.emit('selection-changed');
        }
    };
    Document.prototype.clearSelection = function () {
        this.removeClass('selected', 0, this.chars.length - 1);
    };
    Document.prototype.hasSelection = function () {
        return this.selEnd !== this.selStart;
    };
    return Document;
}());
exports.default = Document;
Observable_1.applyMixins(Document, [Observable_1.Observable]);
