"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CaretPanel = /** @class */ (function () {
    function CaretPanel(parent, $el) {
        this.parent = parent;
        this.$el = $el;
        this.$caret = this.$el.getElementsByClassName('caret')[0];
        this.doc = parent.doc;
        this.hideCaret();
        this.observeDocument();
        this.observeEditor();
        this.observeScroll();
    }
    CaretPanel.prototype.observeDocument = function () {
        var _this = this;
        this.doc.on('selection-changed', function () { return _this.update(); });
    };
    CaretPanel.prototype.observeEditor = function () {
        var _this = this;
        this.parent.editorState.on('focus', function (e) {
            _this.showCaret();
        });
        this.parent.editorState.on('blur', function (e) {
            _this.hideCaret();
        });
    };
    CaretPanel.prototype.observeScroll = function () { };
    CaretPanel.prototype.caretCoordinates = function () {
        var selStart = this.doc.selStart;
        var chars = this.parent.sourcePanel.$el.getElementsByClassName('char');
        var origin = this.parent.sourcePanel.$el.getBoundingClientRect();
        var rect;
        if (chars[selStart]) {
            rect = chars[selStart].getBoundingClientRect();
        }
        else if (chars[selStart - 1] && chars[selStart - 1].innerHTML != '\n') {
            rect = chars[selStart - 1].getBoundingClientRect();
            return {
                x: rect.left - origin.left,
                y: rect.bottom - origin.top,
                width: rect.width,
                height: rect.height
            };
        }
        else {
            var lines = this.parent.sourcePanel.$el.getElementsByClassName('line');
            var line = lines[lines.length - 1];
            rect = line.getBoundingClientRect();
        }
        return {
            x: rect.left - origin.left,
            y: rect.top - origin.top,
            width: rect.width,
            height: rect.height
        };
    };
    CaretPanel.prototype.getCaretCoordinates = function () {
        var coord = { x: 0, y: 0 };
        var selStart = this.doc.selStart;
        var chars = this.parent.sourcePanel.$el.getElementsByClassName('char');
        var next = chars[selStart];
        var previous = chars[selStart - 1];
        var origin = this.$el.getBoundingClientRect();
        if (next) {
            var rect = next.getBoundingClientRect();
            coord.x = rect.left - origin.left;
            coord.y = rect.top - origin.top;
        }
        else if (previous) {
            var rect = previous.getBoundingClientRect();
            if (previous.innerHTML === '\n') {
                var lnum = this.doc.getCurrentLineNum();
                var line = this.parent.sourcePanel.$el.getElementsByClassName('line')[lnum];
                var rect_1 = line.getBoundingClientRect();
                coord.x = rect_1.left - origin.left - 24;
                coord.y = rect_1.top - origin.top;
            }
            else {
                coord.x = rect.left - origin.left;
                coord.y = rect.bottom - origin.top;
            }
        }
        else {
            /*       const line = this.parent.sourcePanel.$el.getElementsByClassName(
              'line'
            )[0];
            let rect = line.getBoundingClientRect();
            coord.x = rect.left - origin.left;
            coord.y = rect.top - origin.top; */
        }
        return coord;
    };
    CaretPanel.prototype.hideCaret = function () {
        this.$caret.style.display = 'none';
        // console.log('hige', this.$caret);
    };
    CaretPanel.prototype.showCaret = function () {
        this.$caret.style.display = 'block';
    };
    CaretPanel.prototype.update = function () {
        if (this.parent.textArea.hasFocus()) {
            this.showCaret();
        }
        else {
            this.hideCaret();
        }
        var selStart = this.doc.selStart;
        var selEnd = this.doc.selEnd;
        if (selStart === selEnd) {
            var newCaret = this.$caret.cloneNode();
            var coord = this.caretCoordinates();
            // console.log(coord);
            newCaret.style.top = coord.y + 'px';
            newCaret.style.left = coord.x + 'px';
            this.$caret.parentNode.replaceChild(newCaret, this.$caret);
            this.$caret = newCaret;
        }
        else {
            this.hideCaret();
        }
    };
    return CaretPanel;
}());
exports.CaretPanel = CaretPanel;
