"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InputStream = /** @class */ (function () {
    function InputStream(input) {
        this.pos = 0;
        this.line = 0;
        this.col = 0;
        this.input = input;
    }
    InputStream.prototype.next = function () {
        var ch = this.input.charAt(this.pos++);
        if (ch === '\n')
            this.line++, (this.col = 0);
        else
            this.col++;
        return ch;
    };
    InputStream.prototype.peek = function () {
        return this.input[this.pos];
    };
    InputStream.prototype.eof = function () {
        return this.peek() === undefined;
    };
    InputStream.prototype.croak = function (msg) {
        throw new Error(msg + ' (' + this.line + ':' + this.col + ')');
    };
    return InputStream;
}());
exports.InputStream = InputStream;
var Tokenizer = /** @class */ (function () {
    function Tokenizer(src) {
        this.is = new InputStream(src);
    }
    Tokenizer.prototype.peek = function () {
        return this.current || (this.current = this.readNext());
    };
    Tokenizer.prototype.next = function () {
        var tok = this.current;
        this.current = null;
        return tok || this.readNext();
    };
    Tokenizer.prototype.eof = function () {
        return this.peek() == null;
    };
    return Tokenizer;
}());
exports.default = Tokenizer;
